import { type Instance, types } from "mobx-state-tree";

export const TmdbMovieModel = types.model("TmdbMovie", {
  id: types.identifierNumber,
  adult: types.optional(types.boolean, false),
  belongs_to_collection: types.maybeNull(
    types.model({
      id: types.number,
      name: types.string,
      poster_path: types.maybeNull(types.string),
      backdrop_path: types.maybeNull(types.string),
    }),
  ),
  budget: types.maybe(types.number),
  backdrop_path: types.maybeNull(types.string),
  genre_ids: types.array(types.number),
  original_language: types.optional(types.string, ""),
  original_title: types.string,
  overview: types.string,
  tagline: types.maybeNull(types.string),
  runtime: types.optional(types.number, 0),
  popularity: types.optional(types.number, 0),
  poster_path: types.maybe(types.string),
  release_date: types.maybe(types.string),
  title: types.optional(types.string, ""),
  video: types.boolean,
  vote_average: types.number,
  vote_count: types.number,
  genres: types.frozen(),
  trailers: types.frozen(),
  videos: types.frozen(),
  production_countries: types.frozen(),
  imdb_id: types.maybeNull(types.string),
});

export type TmdbMovieModelType = Instance<typeof TmdbMovieModel>;
