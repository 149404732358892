import { types, flow, getEnv, cast } from "mobx-state-tree";

import { type Env } from "@/app/store/mobx-store";

import type { TmdbRelatedMovies } from "@/shared/api/tmdb/types";
import { BaseQueryModel } from "@/shared/model/base-query-model";
import { TmdbPaginatedModel } from "@/shared/model/tmdb-paginated-model";

const TmdbRelatedMovieResultModel = types.model("TmdbRelatedMoviesResult", {
  adult: types.boolean,
  backdrop_path: types.maybeNull(types.string),
  genre_ids: types.array(types.number),
  id: types.number,
  original_language: types.string,
  original_title: types.string,
  overview: types.string,
  popularity: types.number,
  poster_path: types.maybeNull(types.string),
  release_date: types.string,
  title: types.string,
  video: types.boolean,
  vote_average: types.number,
  vote_count: types.number,
});

export const RelatedMoviesModel = BaseQueryModel.named("RelatedMoviesModel")
  .props({
    tmdbData: types.maybe(TmdbPaginatedModel(TmdbRelatedMovieResultModel)),
  })
  .actions((self) => ({
    getData: flow(function* (payload: {
      id: number;
      page: number;
      language: string;
    }) {
      if (self.fetchStatus === "loading") {
        return;
      }
      self.fetchStatus = "loading";
      try {
        const tmdbRelatedMoviesResponse: TmdbRelatedMovies = yield getEnv<Env>(
          self,
        ).tmdbClient.getRelatedMovies({
          movieId: payload.id,
          language: payload.language,
          page: payload.page,
        });
        self.tmdbData = cast(tmdbRelatedMoviesResponse);
        self.fetchStatus = "success";
      } catch (err) {
        self.fetchStatus = "error";
      }
    }),
  }));
