import { cast, flow, getEnv, types } from "mobx-state-tree";

import { type Env } from "@/app/store/mobx-store";

import type { TmdbMoviesDiscoverResult } from "@/shared/api/tmdb/types";
import { BasePaginatedQueryModel } from "@/shared/model/base-paginated-query-model";
import {
  TmdbMovieModel,
  TmdbMovieModelType,
} from "@/shared/model/tmdb-movie-model";

const tmdbDiscoverMoviesResultsToTmdbMovieModels = (
  results: TmdbMoviesDiscoverResult["results"],
): TmdbMovieModelType[] => {
  return results.map((result) => cast<TmdbMovieModelType>(result));
};

export const MovieGenresPageModel = BasePaginatedQueryModel.named(
  "MovieGenresPageModel",
)
  .props({
    tmdbData: types.array(TmdbMovieModel),
  })
  .actions((self) => ({
    getMoreData: flow(function* (genreIds: string, language: string) {
      if (self.isLoading) {
        return;
      }

      self.fetchStatus = "loading";

      try {
        const response: TmdbMoviesDiscoverResult = yield getEnv<Env>(
          self,
        ).tmdbClient.discoverMovies({
          with_genres: genreIds,
          page: self.pagination.page,
          language,
        });

        const newData = tmdbDiscoverMoviesResultsToTmdbMovieModels(
          response.results,
        );

        self.tmdbData.push(...newData);

        self.pagination.page = response.page + 1;
        self.pagination.totalPages = response.total_pages;
        self.pagination.totalResults = response.total_results;

        self.fetchStatus = "success";
      } catch {
        self.fetchStatus = "error";
      }
    }),
  }));
