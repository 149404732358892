import { types, flow, getEnv, cast } from "mobx-state-tree";

import { type Env } from "@/app/store/mobx-store";

import type { TmdbRelatedSeries } from "@/shared/api/tmdb/types";
import { BaseQueryModel } from "@/shared/model/base-query-model";
import { TmdbPaginatedModel } from "@/shared/model/tmdb-paginated-model";

const TmdbRelatedTVSeriesResultModel = types.model(
  "TmdbRelatedTVSeriesResult",
  {
    adult: types.boolean,
    backdrop_path: types.maybeNull(types.string),
    genre_ids: types.array(types.number),
    id: types.number,
    origin_country: types.array(types.string),
    original_language: types.string,
    original_name: types.string,
    overview: types.string,
    popularity: types.number,
    poster_path: types.maybeNull(types.string),
    first_air_date: types.string,
    name: types.string,
    vote_average: types.number,
    vote_count: types.number,
  },
);

export const RelatedTVSeriesModel = BaseQueryModel.named("RelatedTVSeriesModel")
  .props({
    tmdbData: types.maybe(TmdbPaginatedModel(TmdbRelatedTVSeriesResultModel)),
  })
  .actions((self) => ({
    getData: flow(function* (payload: {
      id: number;
      page: number;
      language: string;
    }) {
      if (self.fetchStatus === "loading") {
        return;
      }
      self.fetchStatus = "loading";
      try {
        const tmdbRelatedTVSeriesResponse: TmdbRelatedSeries =
          yield getEnv<Env>(self).tmdbClient.getRelatedSeries({
            seriesId: payload.id,
            language: payload.language,
            page: payload.page,
          });
        self.tmdbData = cast(tmdbRelatedTVSeriesResponse);
        self.fetchStatus = "success";
      } catch (err) {
        self.fetchStatus = "error";
      }
    }),
  }));
