import type {
  TmdbGenresResult,
  TmdbMovie,
  TmdbMoviesDiscoverResult,
  TmdbPopularMovies,
  TmdbPopularTVSeries,
  TmdbRelatedMovies,
  TmdbRelatedSeries,
  TmdbSearchMovies,
  TmdbSearchMulti,
  TmdbSearchTVSeries,
  TmdbSeriesDiscoverResult,
  TmdbTVSeries,
  TmdbTimeWindow,
  TmdbTrendingMovies,
  TmdbTrendingTVSeries,
  TmdbTvSeriesBlocked,
} from "./types";

class TmdbClient {
  _baseUrl!: string;

  constructor(baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  // MARK: Movies

  getMovie(id: number, language: string) {
    return this.request<TmdbMovie>(
      `/3/movie/${id}`,
      new URLSearchParams([
        ["append_to_response", "images,videos,trailers,external_ids"],
        ["language", language],
      ]),
    );
  }

  getTrendingMovies(timeWindow: TmdbTimeWindow, language: string) {
    return this.request<TmdbTrendingMovies>(
      `/3/trending/movie/${timeWindow}`,
      new URLSearchParams([["language", language]]),
    );
  }

  searchMovies(query: string, language: string) {
    return this.request<TmdbSearchMovies>(
      "/3/search/movie",
      new URLSearchParams([
        ["query", query],
        ["language", language],
      ]),
    );
  }

  getPopularMovies(
    { page, region }: { page: number; region?: string },
    language: string,
  ) {
    return this.request<TmdbPopularMovies>(
      "/3/movie/popular",
      new URLSearchParams([
        ["page", String(page)],
        ["region", region ?? ""],
        ["language", language],
      ]),
    );
  }

  // MARK: TV Series

  getTVSeries(id: number, language: string) {
    return this.request<TmdbTVSeries | TmdbTvSeriesBlocked>(
      `/3/tv/${id}`,
      new URLSearchParams([
        ["append_to_response", "images,videos,trailers,external_ids"],
        ["language", language],
      ]),
    );
  }

  getTrendingTVSeries(timeWindow: TmdbTimeWindow, language: string) {
    return this.request<TmdbTrendingTVSeries>(
      `/3/trending/tv/${timeWindow}`,
      new URLSearchParams([["language", language]]),
    );
  }

  searchTVSeries(query: string, language: string) {
    return this.request<TmdbSearchTVSeries>(
      "/3/search/tv",
      new URLSearchParams([
        ["query", query],
        ["language", language],
      ]),
    );
  }

  // MARK: Search Multi
  searchMulti(
    { query, page }: { query: string; page: number },
    language: string,
  ) {
    return this.request<TmdbSearchMulti>(
      "/3/search/multi",
      new URLSearchParams([
        ["query", query],
        ["page", String(page)],
        ["language", language],
        ["include_adult", String(false)],
      ]),
    );
  }

  getPopularTVSeries(
    { page, region }: { page: number; region?: string },
    language: string,
  ) {
    return this.request<TmdbPopularTVSeries>(
      "/3/tv/popular",
      new URLSearchParams([
        ["page", String(page)],
        ["region", region ?? ""],
        ["language", language],
      ]),
    );
  }

  formatLocale(loc: string) {
    const locale = new Intl.Locale(loc);
    const max = locale.maximize();

    return `${max.language}-${max.region}`;
  }

  // MARK: Related
  getRelatedMovies({
    page,
    movieId,
    language,
  }: {
    page: number;
    movieId: number;
    language: string;
  }) {
    return this.request<TmdbRelatedMovies>(
      `/3/movie/${movieId}/similar`,
      new URLSearchParams([
        ["page", String(page)],
        ["language", this.formatLocale(language)],
      ]),
    );
  }

  discoverMovies({
    page,
    with_genres,
    language,
  }: {
    page?: number;
    with_genres?: string;
    language: string;
  }) {
    return this.request<TmdbMoviesDiscoverResult>(
      "/3/discover/movie",
      new URLSearchParams([
        ["language", language],
        ["with_genres", String(with_genres || "")],
        ["page", String(page || 1)],
        ["sort_by", "popularity.desc"],
        ["certification_country", "Russia"],
        ["certification", "RU"],
        ["region", "RU"],
        ["watch_region", "RU"],
      ]),
    );
  }

  discoverSeries({
    page,
    with_genres,
    language,
  }: {
    page?: number;
    with_genres?: string;
    language: string;
  }) {
    return this.request<TmdbSeriesDiscoverResult>(
      "/3/discover/tv",
      new URLSearchParams([
        ["language", language],
        ["with_genres", String(with_genres || "")],
        ["page", String(page || 1)],
        ["sort_by", "popularity.desc"],

        ["watch_region", "RU"],
      ]),
    );
  }

  getMoviesGenres({ language }: { language: string }) {
    return this.request<TmdbGenresResult>(
      "/3/genre/movie/list",
      new URLSearchParams([["language", this.formatLocale(language)]]),
    );
  }

  getSeriesGenres({ language }: { language: string }) {
    return this.request<TmdbGenresResult>(
      "/3/genre/tv/list",
      new URLSearchParams([["language", this.formatLocale(language)]]),
    );
  }

  getRelatedSeries({
    page,
    seriesId,
    language,
  }: {
    page: number;
    seriesId: number;
    language: string;
  }) {
    return this.request<TmdbRelatedSeries>(
      `/3/tv/${seriesId}/similar`,
      new URLSearchParams([
        ["page", String(page)],
        ["language", language],
      ]),
    );
  }

  request<T>(path: string, searchParams?: URLSearchParams): Promise<T> {
    const url = new URL(path, this._baseUrl);

    if (searchParams instanceof URLSearchParams) {
      searchParams.forEach((value, key) => {
        url.searchParams.append(key, value);
      });
    }

    return fetch(url).then((r) => r.json() as T);
  }
}

export const tmdbClient = new TmdbClient("https://apitmdb.viewbox.fun");
