import { types, flow, getEnv, cast } from "mobx-state-tree";

import type { TmdbTVSeries } from "@/shared/api/tmdb/types";
import { BaseQueryModel } from "@/shared/model/base-query-model";
import { TmdbTVSeriesModel } from "@/shared/model/tmdb-tv-series-model";

export const TVSeriesPageModel = BaseQueryModel.named("TVSeriesPageModel")
  .props({
    tmdbData: types.maybe(TmdbTVSeriesModel),
  })
  .actions((self) => ({
    getData: flow(function* (id: number, language: string) {
      if (self.fetchStatus === "loading") {
        return;
      }
      self.fetchStatus = "loading";
      try {
        const tmdbTVSeriesResponse: TmdbTVSeries = yield getEnv(
          self,
        ).tmdbClient.getTVSeries(id, language);
        self.tmdbData = cast(tmdbTVSeriesResponse);
        self.fetchStatus = "success";
      } catch {
        self.fetchStatus = "error";
      }
    }),
    resetData(): void {
      self.tmdbData = undefined;
    },
  }));
