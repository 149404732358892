import { types } from "mobx-state-tree";
import { BaseQueryModel } from "./base-query-model";

export const BasePaginatedQueryModel = BaseQueryModel.named(
  "BasePaginatedQueryModel",
).props({
  pagination: types.model({
    page: types.number,
    totalPages: types.maybe(types.number),
    totalResults: types.maybe(types.number),
  }),
});
