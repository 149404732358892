import { cast, flow, getEnv, types } from "mobx-state-tree";

import { type Env } from "@/app/store/mobx-store";

import type { TmdbSeriesDiscoverResult } from "@/shared/api/tmdb/types";
import { BasePaginatedQueryModel } from "@/shared/model/base-paginated-query-model";
import {
  TmdbTVSeriesModel,
  TmdbTVSeriesModelType,
} from "@/shared/model/tmdb-tv-series-model";

const tmdbDiscoverTVSeriesResultsToTmdbTVSeriesModels = (
  results: TmdbSeriesDiscoverResult["results"],
): TmdbTVSeriesModelType[] => {
  return results.map((result) => cast<TmdbTVSeriesModelType>(result));
};

export const TVSeriesGenresPageModel = BasePaginatedQueryModel.named(
  "TVSeriesGenresPageModel",
)
  .props({
    tmdbData: types.array(TmdbTVSeriesModel),
  })
  .actions((self) => ({
    getMoreData: flow(function* (genreIds: string, language: string) {
      if (self.isLoading) {
        return;
      }

      self.fetchStatus = "loading";

      try {
        const response: TmdbSeriesDiscoverResult = yield getEnv<Env>(
          self,
        ).tmdbClient.discoverSeries({
          with_genres: genreIds,
          page: self.pagination.page,
          language,
        });

        const newData = tmdbDiscoverTVSeriesResultsToTmdbTVSeriesModels(
          response.results,
        );

        self.tmdbData.push(...newData);

        self.pagination.page = response.page + 1;
        self.pagination.totalPages = response.total_pages;
        self.pagination.totalResults = response.total_results;

        self.fetchStatus = "success";
      } catch (err) {
        self.fetchStatus = "error";
      }
    }),
  }));
