import type { SourcesResponse } from "./types";

import { HttpError } from "@/shared/api/http-error";

class ViewBoxSourceClient {
  _baseUrl!: string;

  constructor(baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  async getSourcesByTmdb(tmdbMediaType: "movie" | "tv", tmdbId: number) {
    const url = new URL(
      `/external/v1/sources/tmdb/${tmdbMediaType}/${tmdbId}`,
      this._baseUrl,
    );

    const res = await fetch(new Request(url), {
      method: "GET",
    });
    if (!res.ok) {
      throw new HttpError(await res.json(), { status: res.status });
    }

    return res.json() as Promise<SourcesResponse>;
  }
}

export const viewBoxSourceClient = new ViewBoxSourceClient(
  "https://apisource.viewbox.fun",
);
