import {
  applySnapshot,
  type Instance,
  type IStateTreeNode,
  type IType,
  onSnapshot,
  types,
} from "mobx-state-tree";
import localForage from "localforage";

import { WatchHistoryModel } from "@/entities/watch-history/model/watch-history-model";
import { PlayerModel } from "@/entities/player/model/player-model";
import { SourcesModel } from "@/entities/source/model/sources-model";
import { viewBoxSourceClient } from "@/entities/source/lib/api/viewbox-source/client";

import { AddToHomeModel } from "@/features/add-to-home/model/add-to-home-model";
import { RelatedMoviesModel } from "@/features/movie/related-movies/model/related-movies-model";
import { RelatedTVSeriesModel } from "@/features/tv/related-tv-series/model/related-tv-series.model";

import { MoviePageModel } from "@/pages/movie/model/movie-page-model";
import { TVSeriesPageModel } from "@/pages/tv/model/tv-series-page-model";
import { MovieGenresPageModel } from "@/pages/collections/model/movie-genres-page-model";
import { TVSeriesGenresPageModel } from "@/pages/collections/model/tv-genres-page-model";

import { tmdbClient } from "@/shared/api/tmdb/client";
import { kodikClient } from "@/shared/api/kodik/client";
import { wikidataClient } from "@/shared/api/wikidata/client";

export const RootModel = types.model("RootModel", {
  watchHistory: WatchHistoryModel,
  player: PlayerModel,
  addToHome: AddToHomeModel,
  sources: SourcesModel,
  moviePage: MoviePageModel,
  tvSeriesPage: TVSeriesPageModel,
  movieGenresPage: MovieGenresPageModel,
  tvSeriesGenresPage: TVSeriesGenresPageModel,
  relatedMovies: RelatedMoviesModel,
  relatedTVSeries: RelatedTVSeriesModel,
});

export type Store = Readonly<Instance<typeof RootModel>>;

const env = {
  viewBoxSourceClient,
  tmdbClient,
  kodikClient,
  wikidataClient,
};

export type Env = Readonly<typeof env>;

export const store = RootModel.create(
  {
    watchHistory: {
      records: [],
    },
    player: {
      selectedSource: "TURBO",
    },
    addToHome: {
      hidden: false,
    },
    sources: {
      fetchStatus: "idle",
      sources: [],
      kodik: {
        fetchStatus: "idle",
        iframe: {
          url: null,
        },
        season: 0,
        translation: 0,
      },
    },
    moviePage: {
      fetchStatus: "idle",
    },
    tvSeriesPage: {
      fetchStatus: "idle",
    },
    movieGenresPage: {
      fetchStatus: "idle",
      pagination: {
        page: 1,
      },
    },
    tvSeriesGenresPage: {
      fetchStatus: "idle",
      pagination: {
        page: 1,
      },
    },
    relatedMovies: {
      fetchStatus: "idle",
    },
    relatedTVSeries: {
      fetchStatus: "idle",
    },
  },
  env,
);

function persist(
  target: IStateTreeNode<IType<any, unknown, any>>,
  key: string,
) {
  if (typeof window === "undefined") {
    return;
  }

  onSnapshot(target, async (snapshot) => {
    await localForage.setItem(key, snapshot);
  });
}

function hydrate(
  target: IStateTreeNode<IType<any, unknown, any>>,
  key: string,
): Promise<void> {
  if (typeof window === "undefined") {
    return Promise.resolve();
  }

  return localForage.getItem(key).then((value) => {
    if (value === null) {
      return;
    }

    applySnapshot(target, value);
  });
}

persist(store.watchHistory, "watchHistory");
persist(store.player, "player");
persist(store.addToHome, "addToHome");

if (typeof window !== "undefined") {
  Promise.allSettled([
    hydrate(store.watchHistory, "watchHistory"),
    hydrate(store.player, "player"),
    hydrate(store.addToHome, "addToHome"),
  ]);
}
